import React from 'react';
import styled from "styled-components";
import "../index.css"
import Header from "../components/header";
import Footer from "../components/footer";
import YandexMap from "../components/yandexMap"
import Decoration from "../images/decoration.svg";
import {graphql, useStaticQuery} from "gatsby";
import Seo from "../components/seo";

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

const Container = styled.div`
  width: 1110px;
  align-self: center;
  margin-bottom: 33px;
  position: relative;
  @media screen and (max-width: 1100px) {
    width: 1000px;
  }
  @media screen and (max-width: 960px) {
    width: 850px;
  }
  @media screen and (max-width: 800px) {
    width: 700px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 30px;
  color: #4C77FF;
  margin-bottom: 37px;
  @media screen and (max-width: 1100px) {
    font-size: 25px;
    margin-bottom: 30px;
  }
  @media screen and (max-width: 960px) {
    font-size: 18px;
    margin-bottom: 23px;
  }
  @media screen and (max-width: 800px) {
    font-size: 15px;
    margin-bottom: 17px;
  }
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 140%;
  color: #212939;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;

const Mailto = styled.a`
  font-family: Montserrat;
  font-size: 16px;
  line-height: 20px;
  color: #4C77FF;
  text-decoration: none;
  font-weight: 600;
  @media screen and (max-width: 1100px) {
    font-size: 13px;
    line-height: 18px;
  }
  @media screen and (max-width: 960px) {
    font-size: 11px;
    line-height: 15px;
  }
  @media screen and (max-width: 800px) {
    font-size: 9px;
    line-height: 12px;
  }
`;


const ReturnImages = () => {
    return (
        <>
            <Image src={Decoration} alt="decoration"/>
        </>
    )
}

const Image = styled.img`
  position: absolute;
  left: -440px;
  top: 120px;
  @media screen and (max-width: 1500px) {
    display: none;
  }
`;

const Partners = () => {
    const data = useStaticQuery(query);
    return (
        <div style={{minHeight: "100%", display: "flex", flexDirection: "column", justifyContent: "space-between"}}>
            <Seo title={data.allStrapiContactsSeos.nodes[0].seo_title}
                 description={data.allStrapiContactsSeos.nodes[0].seo_description}
                 keywords={data.allStrapiContactsSeos.nodes[0].seo_keywords.split(',')}
            />
            <MainContainer>
                <div style={{alignSelf: "center"}}>
                    <Header/>
                </div>
                <Container>
                    <ReturnImages/>
                    <Title>
                        Контакты
                    </Title>
                    <Text>
                        Общество с ограниченной ответственностью "Пластполимер-Сибирь" (ООО «Пластполимер-Сибирь)<br/><br/>
                        Юридический адрес: 634041, Томская область, г. Томск, проспект Кирова , д. 23, помещение 40<br/>
                        Почтовый адрес: 634041, Томская область, г. Томск, проспект Кирова , д. 23, помещение 40<br/>
                        Электронная почта: <Mailto href="mailto:kadry@pp-proekt.ru"> kadry@pp-proekt.ru </Mailto>
                    </Text>
                </Container>
                <YandexMap/>
            </MainContainer>
            <Footer/>
        </div>
    )
}

const query = graphql`
query{
  allStrapiContactsSeos {
    nodes {
      seo_title
      seo_decsription
      seo_keywords
    }
  }
}
`;

export default Partners;